<template>
	<div>
		<ZyroFieldText
			:id="`${currentElementId}-text`"
			:label="$t('builder.editVideo.tabAction.label')"
			:error="!isUrlValid && $t('validate.url')"
			:value="srcInput"
			data-qa="edit-video-popup-action-inputfield-url"
			@input="srcInput = $event.target.value"
		/>
		<div class="preview">
			<GridVideo
				data-qa="edit-video-popup-action-video"
				v-bind="composedProps"
			/>
		</div>
		<div class="toggle-container">
			<ZyroFieldToggle
				v-for="({
					paramKey, paramLabel, paramValue, qaAttribute
				}) in optionsToggles"
				:id="`${paramKey}-toggle`"
				:key="paramKey"
				:label="paramLabel"
				:checked="paramValue === TRUTHY_PARAM_VALUE"
				:disabled="!isUrlValid"
				:data-qa="qaAttribute"
				@change="handleToggleChange(paramKey, paramValue)"
			/>
		</div>
	</div>
</template>

<script>
import {
	ref,
	reactive,
	watchEffect,
	onBeforeUnmount,
	getCurrentInstance,
} from '@vue/composition-api';

import i18n from '@/i18n/setup';
import {
	NOTIFICATIONS_NAMESPACE,
	NOTIFY,
} from '@/store/builder/notifications';
import { cloneDeep } from '@/utils/object';
import GridVideo from '@user/components/grid-components/video/GridVideo.vue';
import {
	URL_PARAMS,
	FALSY_PARAM_VALUE,
	TRUTHY_PARAM_VALUE,
	DEFAULT_WIDTH,
	DEFAULT_HEIGHT,
	DEFAULT_URL,
	DEFAULT_PROVIDER,
	DEFAULT_AUTOPLAY_VALUE,
	DEFAULT_LOOP_VALUE,
	DEFAULT_CONTROLS_VALUE,
} from '@user/components/grid-components/video/constants';
import {
	extractVideoProps,
	extractVideoParams,
} from '@user/components/grid-components/video/extractVideoProps';

export default {
	components: { GridVideo },
	setup() {
		const {
			state,
			getters,
			commit,
			dispatch,
		} = getCurrentInstance()?.$store;
		const { currentElementId } = state;
		const initialCurrentElement = cloneDeep(getters.currentElement);
		const initialSettings = initialCurrentElement.settings;

		const sourceInput = ref(initialSettings.initialSrc ?? initialSettings.src ?? DEFAULT_URL);
		const extractedParameters = extractVideoParams(initialSettings.src) ?? {};

		const isUrlValid = ref(true);

		const composedProperties = reactive({
			initialSrc: initialSettings.initialSrc ?? DEFAULT_URL,
			src: initialSettings.src ?? DEFAULT_URL,
			provider: initialSettings.provider ?? DEFAULT_PROVIDER,
			webp: initialSettings.webp,
			jpg: initialSettings.jpg,
			width: initialSettings.width ?? DEFAULT_WIDTH,
			height: initialSettings.height ?? DEFAULT_HEIGHT,
		});

		// get option toggle values from initial url
		const optionsToggles = reactive({
			[URL_PARAMS.AUTOPLAY]: {
				paramKey: URL_PARAMS.AUTOPLAY,
				paramLabel: i18n.t('builder.editVideo.tabAction.modifiers.autoplay'),
				paramValue: extractedParameters[URL_PARAMS.AUTOPLAY] ?? DEFAULT_AUTOPLAY_VALUE,
				qaAttribute: 'edit-video-popup-action-toggle-autoplay',
			},
			[URL_PARAMS.LOOP]: {
				paramKey: URL_PARAMS.LOOP,
				paramLabel: i18n.t('builder.editVideo.tabAction.modifiers.loop'),
				paramValue: extractedParameters[URL_PARAMS.LOOP] ?? DEFAULT_LOOP_VALUE,
				qaAttribute: 'edit-video-popup-action-toggle-loop',
			},
			[URL_PARAMS.CONTROLS]: {
				paramKey: URL_PARAMS.CONTROLS,
				paramLabel: i18n.t('builder.editVideo.tabAction.modifiers.controls'),
				paramValue: extractedParameters[URL_PARAMS.CONTROLS] ?? DEFAULT_CONTROLS_VALUE,
				qaAttribute: 'edit-video-popup-action-toggle-controls',
			},
		});

		const handleToggleChange = async (parameterKey, parameterValue) => {
			optionsToggles[parameterKey].paramValue = parameterValue === TRUTHY_PARAM_VALUE
				? FALSY_PARAM_VALUE
				: TRUTHY_PARAM_VALUE;
		};

		// watch for changes both inside toggles and inputs and recalculate `composedProps`
		watchEffect(async () => {
			const extractedProperties = await extractVideoProps(sourceInput.value, {
				autoplay: optionsToggles[URL_PARAMS.AUTOPLAY].paramValue,
				loop: optionsToggles[URL_PARAMS.LOOP].paramValue,
				controls: optionsToggles[URL_PARAMS.CONTROLS].paramValue,
			}).catch((error) => {
				const message = `A video with provided URL was not found. ${error.message}`;

				dispatch(`${NOTIFICATIONS_NAMESPACE}/${NOTIFY}`, {
					message,
					origin: 'EditVideoTabAction.vue',
				});
			});

			isUrlValid.value = extractedProperties.isUrlValid;

			if (!isUrlValid.value) {
				return;
			}

			Object.entries(extractedProperties).forEach(([key, value]) => { composedProperties[key] = value; });

			commit('setElementData', {
				elementId: currentElementId,
				data: {
					settings: {
						initialSrc: sourceInput.value,
						src: composedProperties.src,
						jpg: composedProperties.jpg,
						webp: composedProperties.webp,
						provider: composedProperties.provider,
					},
				},
			});
		});

		onBeforeUnmount(() => {
			const mutationType = isUrlValid.value
				? 'pushElementDataToHistory'
				: 'setElementData';

			commit(mutationType, {
				elementId: currentElementId,
				data: initialCurrentElement,
			});
		});

		return {
			currentElementId,
			optionsToggles,
			handleToggleChange,
			srcInput: sourceInput,
			isUrlValid,
			composedProps: composedProperties,
			TRUTHY_PARAM_VALUE,
		};
	},
};
</script>

<style lang="scss" scoped>
.toggle-container > .zyro-field-toggle {
	$gray-border: 0.5px solid $grey-300;

	&:first-child {
		margin-top: 16px;
		border-top: $gray-border;
	}

	border-bottom: $gray-border;

	&:last-child {
		border-bottom: none;
	}
}

.zyro-field-toggle__group {
	display: flex;
	justify-content: space-between;
	padding: 16px 0;
}

.preview {
	position: relative;
	width: 100%;
	height: 176px;
	object-fit: fill;
}
</style>
