<template>
	<BaseEditControls
		:block-id="blockId"
		:block-ref="blockRef"
		:button-title="$t('builder.editVideo.title')"
	>
		<ZyroPopupCard
			type="editor"
			:title="$t('builder.editVideo.popupTitle')"
			:tabs="$options.TABS"
			:current-tab="currentTab"
			@change="currentTab = $event"
			@close="$emit('close')"
		>
			<Component :is="currentTab.component" />
		</ZyroPopupCard>
	</BaseEditControls>
</template>

<script>
import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import i18n from '@/i18n/setup';

import EditVideoTabAction from './EditVideoTabAction.vue';
import EditVideoTabLayout from './EditVideoTabLayout.vue';

const TABS = [
	{
		title: i18n.t('common.general'),
		component: 'EditVideoTabAction',
	},
	{
		title: i18n.t('common.layout'),
		component: 'EditVideoTabLayout',
	},
];

export default {
	components: {
		BaseEditControls,
		EditVideoTabAction,
		EditVideoTabLayout,
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
	},
	data() {
		return { currentTab: TABS[0] };
	},
	TABS,
};
</script>
